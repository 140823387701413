import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SubscriptionExpired = () => {
  const { t } = useTranslation();

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <Card>
            <Card.Body>
              <Card.Title>{t('subscriptionExpired')}</Card.Title>
              <Card.Text>
                {t('subscriptionExpiredMessage')}
              </Card.Text>
              <Card.Text className="mt-3 text-muted">
                {t('teamSignature')}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SubscriptionExpired;