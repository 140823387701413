import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Table, Button, Modal, Form, Row, Col, Card, Pagination, Tab, Tabs } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import { functions } from './firebase';
import { httpsCallable } from 'firebase/functions';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AdminPanel = () => {
  const { isAdmin } = useAuth();
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [expiryDate, setExpiryDate] = useState('');
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  const [usageLogs, setUsageLogs] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedEmails, setSelectedEmails] = useState([]);

  const [allFilteredLogs, setAllFilteredLogs] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  const [currentUserPage, setCurrentUserPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [totalUserPages, setTotalUserPages] = useState(0);

  const [sortConfig, setSortConfig] = useState({
    key: 'email',
    direction: 'asc'
  });

  const [filteredUsers, setFilteredUsers] = useState([]);

  const [activeTab, setActiveTab] = useState('users');

  const [groups, setGroups] = useState([]);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupDescription, setNewGroupDescription] = useState('');

  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupUsers, setGroupUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);

  const [selectedUserGroups, setSelectedUserGroups] = useState([]);

  const [userGroups, setUserGroups] = useState({});

  const [selectedGroupFilter, setSelectedGroupFilter] = useState(null);

  const [isLoadingLogs, setIsLoadingLogs] = useState(false);

  const [selectedGroupsFilter, setSelectedGroupsFilter] = useState([]);

  const formatDate = (dateInput) => {
    if (!dateInput) return '-';
    const date = new Date(dateInput);
    if (isNaN(date.getTime())) return '-';
    return date.toISOString().split('T')[0] + ' ' + date.toTimeString().split(' ')[0];
  };

  const emailOptions = useMemo(() => {
    return userSubscriptions.map((sub) => ({ value: sub.email, label: sub.email }));
  }, [userSubscriptions]);

  // Serialize selectedEmails for use in dependencies
  const selectedEmailsValues = useMemo(
    () => selectedEmails.map((option) => option.value).join(','),
    [selectedEmails]
  );

  const fetchUserSubscriptions = useCallback(async () => {
    if (isAdmin) {
      try {
        const getUserSubscriptions = httpsCallable(functions, 'getusersubscriptions');
        const result = await getUserSubscriptions();
        setUserSubscriptions(result.data.results);
      } catch (error) {
        console.error('Error fetching user subscriptions:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [isAdmin]);

  const fetchUsageLogs = useCallback(async () => {
    if (isAdmin) {
      setIsLoadingLogs(true);
      try {
        const getUserUsageLogs = httpsCallable(functions, 'getUserUsageLogs');
        const result = await getUserUsageLogs({
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null,
          page: currentPage,
          logsPerPage: logsPerPage,
          emails: selectedEmails.map((option) => option.value),
          groupIds: selectedGroupsFilter.map((option) => option.value),
        });
        setUsageLogs(result.data.results);
        setTotalPages(Math.ceil(result.data.totalCount / logsPerPage));
      } catch (error) {
        console.error('Error fetching user usage logs:', error);
      } finally {
        setIsLoadingLogs(false);
      }
    }
  }, [isAdmin, startDate, endDate, currentPage, logsPerPage, selectedEmailsValues, selectedGroupsFilter]);

  const fetchAllFilteredLogs = useCallback(async () => {
    if (isAdmin) {
      try {
        const getUserUsageLogs = httpsCallable(functions, 'getUserUsageLogs');
        const result = await getUserUsageLogs({
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null,
          page: 1,
          logsPerPage: 1000000, // A large number to fetch all logs
          emails: selectedEmails.map((option) => option.value),
        });

        // Filter logs by selected groups if any are selected
        let filteredResults = result.data.results;
        if (selectedGroupsFilter.length > 0) {
          const selectedGroupIds = selectedGroupsFilter.map(g => g.value);
          filteredResults = filteredResults.filter(log => {
            const userGroupsList = userGroups[log.email] || [];
            return userGroupsList.some(group => selectedGroupIds.includes(group.id));
          });
        }

        return filteredResults;
      } catch (error) {
        console.error('Error fetching all filtered user usage logs:', error);
        return [];
      }
    }
    return [];
  }, [isAdmin, startDate, endDate, selectedEmailsValues, selectedGroupsFilter, userGroups]);

  const handleExcelExport = async () => {
    const allLogs = await fetchAllFilteredLogs();
    
    // Apply group filters client-side if any groups are selected
    let filteredLogs = allLogs;
    if (selectedGroupsFilter.length > 0) {
      const selectedGroupIds = selectedGroupsFilter.map(g => g.value);
      filteredLogs = allLogs.filter(log => {
        const userGroupsList = userGroups[log.email] || [];
        return userGroupsList.some(group => selectedGroupIds.includes(group.id));
      });
    }

    // Format the data for Excel export
    const formattedLogs = filteredLogs.map(log => ({
      Email: log.email,
      Groups: userGroups[log.email]?.map(g => g.name).join(', ') || '-',
      Action_Type: log.action_type,
      Details: JSON.stringify(log.details),
      Timestamp: formatDate(log.created_at)
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedLogs);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Usage Logs');
    XLSX.writeFile(workbook, 'usage_logs.xlsx');
  };

  const fetchUserGroupsData = useCallback(async () => {
    if (!isAdmin) return;
    
    try {
      const getGroupUsers = httpsCallable(functions, 'getGroupUsers');
      const groupsResult = await getGroupUsers({ getAllUsers: true });
      console.log('All user groups:', groupsResult.data);
      setUserGroups(groupsResult.data.userGroups || {});
    } catch (error) {
      console.error('Error fetching user groups:', error);
    }
  }, [isAdmin]);

  useEffect(() => {
    if (isAdmin) {
      fetchUserSubscriptions();
      fetchUserGroupsData();
    }
  }, [isAdmin, fetchUserSubscriptions, fetchUserGroupsData]);

  useEffect(() => {
    setTotalUserPages(Math.ceil(userSubscriptions.length / usersPerPage));
  }, [userSubscriptions, usersPerPage]);

  useEffect(() => {
    // First filter
    const filtered = userSubscriptions.filter(filterBySearchTerm);
    
    // Then sort
    const sorted = [...filtered].sort((a, b) => {
      if (!a[sortConfig.key] && !b[sortConfig.key]) return 0;
      if (!a[sortConfig.key]) return 1;
      if (!b[sortConfig.key]) return -1;

      const aValue = a[sortConfig.key].toString().toLowerCase();
      const bValue = b[sortConfig.key].toString().toLowerCase();

      if (sortConfig.direction === 'asc') {
        return aValue.localeCompare(bValue);
      }
      return bValue.localeCompare(aValue);
    });

    setFilteredUsers(sorted);
    setCurrentUserPage(1); // Reset to first page when filter/sort changes
    setTotalUserPages(Math.ceil(sorted.length / usersPerPage));
  }, [userSubscriptions, searchTerm, sortConfig, usersPerPage, selectedGroupFilter]);

  const indexOfLastUser = currentUserPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleEditClick = async (user) => {
    console.log('Edit clicked for user:', user);
    setSelectedUser(user);
    setExpiryDate(user.expiry_date || '');
    setShowEditModal(true);

    try {
      // Check admin status
      const checkUserAdminStatus = httpsCallable(functions, 'checkuseradminstatus');
      const result = await checkUserAdminStatus({ email: user.email });
      console.log('Admin status result:', result.data);
      setIsUserAdmin(result.data.isAdmin);

      // Fetch user's groups
      const getUserGroups = httpsCallable(functions, 'getUserGroups');
      const userGroupsResult = await getUserGroups({ email: user.email });
      console.log('User groups raw result:', userGroupsResult.data);
      
      if (userGroupsResult.data.groups && Array.isArray(userGroupsResult.data.groups)) {
        const formattedUserGroups = userGroupsResult.data.groups
          .filter(group => group && group.id)
          .map(group => ({
            value: group.id.toString(),
            label: group.name || `Group ${group.id}`
          }));
        
        console.log('Formatted user groups:', formattedUserGroups);
        setSelectedUserGroups(formattedUserGroups);
      } else {
        console.log('No groups found for user');
        setSelectedUserGroups([]);
      }
    } catch (error) {
      console.error('Error in handleEditClick:', error);
      setIsUserAdmin(false);
      setSelectedUserGroups([]);
    }
  };

  const handleSave = async () => {
    try {
      const updateUserSubscription = httpsCallable(functions, 'updateusersubscription');
      await updateUserSubscription({
        email: selectedUser.email,
        expiry_date: expiryDate,
        admin: isUserAdmin,
      });
      setUserSubscriptions((prev) =>
        prev.map((user) =>
          user.email === selectedUser.email
            ? { ...user, expiry_date: expiryDate, admin: isUserAdmin, updated_at: new Date().toISOString() }
            : user
        )
      );

      setShowEditModal(false);
    } catch (error) {
      console.error('Error updating user subscription:', error);
    }
  };

  const groupUsageLogs = (logs) => {
    return logs.reduce((acc, log) => {
      if (!acc[log.email]) {
        acc[log.email] = {};
      }
      if (!acc[log.email][log.action_type]) {
        acc[log.email][log.action_type] = 0;
      }
      acc[log.email][log.action_type] += 1;
      return acc;
    }, {});
  };

  const handleDateChange = (date, dateType) => {
    if (dateType === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
    setCurrentPage(1);
  };

  const handleEmailFilterChange = (selectedOptions) => {
    setSelectedEmails(selectedOptions);
    setCurrentPage(1);
  };

  const renderGroupedUsageReport = () => {
    if (isLoadingLogs) {
      return <div>{t('loading')}</div>;
    }
    
    if (!allFilteredLogs.length) {
      return <div>{t('noDataAvailable')}</div>;
    }
    
    // Filter logs based on selected groups
    let filteredLogs = [...allFilteredLogs];
    if (selectedGroupsFilter.length > 0) {
      const selectedGroupIds = selectedGroupsFilter.map(g => g.value);
      filteredLogs = filteredLogs.filter(log => {
        const userGroupsList = userGroups[log.email] || [];
        return userGroupsList.some(group => selectedGroupIds.includes(group.id));
      });
    }
    
    const groupedLogs = groupUsageLogs(filteredLogs);
    
    const chartData = {
      labels: Object.keys(groupedLogs),
      datasets: [
        {
          label: t('totalActions'),
          data: Object.values(groupedLogs).map(actions => 
            Object.values(actions).reduce((sum, count) => sum + count, 0)
          ),
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
      ],
    };

    const chartOptions = {
      indexAxis: 'y',
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: t('groupedUsageReport'),
        },
      },
      scales: {
        x: {
          beginAtZero: true,
        },
      },
    };

    return (
      <div>
        <Bar data={chartData} options={chartOptions} />
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>{t('email')}</th>
              <th>{t('groups')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedLogs).map(([email, actions], index) => (
              <tr key={index}>
                <td>{email}</td>
                <td>{userGroups[email]?.map(g => g.name).join(', ') || '-'}</td>
                <td>
                  {Object.entries(actions).map(([action, count], actionIndex) => (
                    <div key={actionIndex}>
                      {action}: {count}
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderDetailedUsageReport = () => {
    if (isLoadingLogs) {
      return <div>{t('loading')}</div>;
    }
    
    // Filter logs based on selected groups
    let filteredLogs = [...usageLogs];
    if (selectedGroupsFilter.length > 0) {
      const selectedGroupIds = selectedGroupsFilter.map(g => g.value);
      filteredLogs = filteredLogs.filter(log => {
        const userGroupsList = userGroups[log.email] || [];
        return userGroupsList.some(group => selectedGroupIds.includes(group.id));
      });
    }
    
    return (
      <div>
        <Form className="mb-3">
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>{t('startDate')}</Form.Label>
                <DatePicker
                  className="form-control mx-1"
                  selected={startDate}
                  onChange={(date) => handleDateChange(date, 'start')}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>{t('endDate')}</Form.Label>
                <DatePicker
                  className="form-control mx-1"
                  selected={endDate}
                  onChange={(date) => handleDateChange(date, 'end')}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>{t('filterBy')}</Form.Label>
                <div className="d-flex flex-column gap-2">
                  <Select
                    isMulti
                    options={emailOptions}
                    value={selectedEmails}
                    onChange={(selected) => {
                      handleEmailFilterChange(selected);
                      // Clear group filter when emails are selected
                      if (selected.length > 0) {
                        setSelectedGroupsFilter([]);
                      }
                    }}
                    placeholder={t('selectEmails')}
                    isDisabled={selectedGroupsFilter.length > 0}
                  />
                  <Select
                    isMulti
                    options={groupFilterOptions}
                    value={selectedGroupsFilter}
                    onChange={(selected) => {
                      setSelectedGroupsFilter(selected);
                      // Clear email filter when groups are selected
                      if (selected.length > 0) {
                        setSelectedEmails([]);
                      }
                    }}
                    placeholder={t('selectGroups')}
                    isDisabled={selectedEmails.length > 0}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Button variant="success" onClick={handleExcelExport}>
            {t('exportToExcel')}
          </Button>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t('email')}</th>
              <th>{t('groups')}</th>
              <th>{t('actionType')}</th>
              <th>{t('details')}</th>
              <th>{t('timestamp')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredLogs.map((log, index) => (
              <tr key={index}>
                <td>{log.email}</td>
                <td>{userGroups[log.email]?.map(g => g.name).join(', ') || '-'}</td>
                <td>{log.action_type}</td>
                <td>{JSON.stringify(log.details)}</td>
                <td>{formatDate(log.created_at)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {totalPages > 0 && (
          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
            <Pagination.Prev
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            />
            {[...Array(Math.min(totalPages, 5))].map((_, i) => {
              const pageNumber = currentPage + i - Math.min(currentPage - 1, 2);
              return (
                pageNumber > 0 && pageNumber <= totalPages && (
                  <Pagination.Item
                    key={pageNumber}
                    active={pageNumber === currentPage}
                    onClick={() => setCurrentPage(pageNumber)}
                  >
                    {pageNumber}
                  </Pagination.Item>
                )
              );
            })}
            <Pagination.Next
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
          </Pagination>
        )}
      </div>
    );
  };

  const filterBySearchTerm = (item) => {
    if (!searchTerm && !selectedGroupFilter) return true;
    
    const searchLower = searchTerm.toLowerCase();
    const userGroupsList = userGroups[item.email] || [];
    
    const matchesSearch = !searchTerm || (
      item.email?.toLowerCase().includes(searchLower) ||
      item.display_name?.toLowerCase().includes(searchLower)
    );
    
    const matchesGroup = !selectedGroupFilter || 
      userGroupsList.some(group => group.id === selectedGroupFilter.value);
    
    return matchesSearch && matchesGroup;
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  useEffect(() => {
    const fetchGroups = async () => {
      if (isAdmin) {
        try {
          const getGroupsFunc = httpsCallable(functions, 'getGroups');
          const result = await getGroupsFunc();
          console.log('Fetched groups:', result.data.groups);
          setGroups(result.data.groups);
        } catch (error) {
          console.error('Error fetching groups:', error);
        }
      }
    };

    fetchGroups();
  }, [isAdmin]);

  const handleCreateGroup = async () => {
    try {
      const createGroupFunc = httpsCallable(functions, 'createGroup');
      await createGroupFunc({
        name: newGroupName || '',
        description: newGroupDescription || ''
      });
      
      // Clear form and close modal
      setNewGroupName('');
      setNewGroupDescription('');
      setShowGroupModal(false);
      
      // Refresh groups
      const getGroupsFunc = httpsCallable(functions, 'getGroups');
      const result = await getGroupsFunc();
      setGroups(result.data.groups);
    } catch (error) {
      console.error('Error creating group:', error);
    }
  };

  const handleUpdateUserGroups = async (email, selectedOptions) => {
    console.log('Updating groups for email:', email);
    console.log('Selected options:', selectedOptions);
    
    if (!email) {
      console.error('No email provided');
      return;
    }

    try {
      const updateUserGroups = httpsCallable(functions, 'updateUserGroups');
      const groupIds = selectedOptions
        .filter(option => option && option.value)
        .map(option => parseInt(option.value))
        .filter(id => !isNaN(id));
      
      console.log('Sending group IDs:', groupIds);
      await updateUserGroups({
        email,
        groupIds
      });
      
      // Refresh the user's groups after update
      const getUserGroups = httpsCallable(functions, 'getUserGroups');
      const result = await getUserGroups({ email });
      console.log('Updated user groups:', result.data);
      
      if (result.data.groups && Array.isArray(result.data.groups)) {
        const formattedGroups = result.data.groups
          .filter(group => group && group.id && group.name)
          .map(group => ({
            value: group.id.toString(),
            label: group.name || `Group ${group.id}`
          }));
        setSelectedUserGroups(formattedGroups);
      } else {
        setSelectedUserGroups([]);
      }
      
    } catch (error) {
      console.error('Error updating user groups:', error);
    }
  };

  const handleEditGroupClick = async (group) => {
    if (!group) return;
    
    setSelectedGroup({
      id: group.id,
      name: group.name || '',
      description: group.description || ''
    });
    
    try {
      // Fetch current group users
      const getGroupUsers = httpsCallable(functions, 'getGroupUsers');
      const groupUsersResult = await getGroupUsers({ groupId: group.id });
      console.log('Group users result:', groupUsersResult.data);
      
      setGroupUsers(groupUsersResult.data.users || []);
      
      // Set all available users as options
      const formattedAvailableUsers = userSubscriptions
        .filter(user => user.email) // Ensure email exists
        .map(user => ({
          value: user.email,
          label: user.email,
          display_name: user.display_name
        }));
      
      setAvailableUsers(formattedAvailableUsers);
    } catch (error) {
      console.error('Error fetching group users:', error);
      setGroupUsers([]);
      setAvailableUsers([]);
    }
    
    setShowEditGroupModal(true);
  };

  const handleUpdateGroupUsers = async (selectedOptions) => {
    if (!selectedGroup) return;
    
    const formattedUsers = selectedOptions ? selectedOptions.map(option => ({
      value: option.value,
      label: option.label
    })) : [];
    
    setGroupUsers(formattedUsers);
    
    try {
      const updateGroupUsersFunc = httpsCallable(functions, 'updateGroupUsers');
      await updateGroupUsersFunc({
        groupId: selectedGroup.id,
        userEmails: formattedUsers.map(user => user.value)
      });
    } catch (error) {
      console.error('Error updating group users:', error);
    }
  };

  const handleUpdateGroup = async () => {
    try {
      const updateGroupFunc = httpsCallable(functions, 'updateGroup');
      await updateGroupFunc({
        id: selectedGroup.id,
        name: selectedGroup.name,
        description: selectedGroup.description
      });
      
      // Refresh groups
      const getGroupsFunc = httpsCallable(functions, 'getGroups');
      const result = await getGroupsFunc();
      setGroups(result.data.groups);
      
      setShowEditGroupModal(false);
    } catch (error) {
      console.error('Error updating group:', error);
    }
  };

  const groupFilterOptions = useMemo(() => {
    return groups.map(group => ({
      value: group.id,
      label: group.name || `Group ${group.id}`
    }));
  }, [groups]);

  useEffect(() => {
    if (activeTab === 'groupedReport' || activeTab === 'detailedReport') {
      fetchUsageLogs();
      fetchAllFilteredLogs().then(logs => {
        setAllFilteredLogs(logs);
      });
    }
  }, [activeTab, fetchUsageLogs, fetchAllFilteredLogs]);

  if (!isAdmin) {
    return <Container>{t('adminAccessDenied')}</Container>;
  }

  if (loading) {
    return <Container>{t('loading')}</Container>;
  }

  return (
    <Container className="mt-3">
      <h1>{t('adminPanel')}</h1>

      <Tabs
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        className="mb-3"
      >
        <Tab eventKey="users" title={t('userSubscriptions')}>
          <Form className="mb-3">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder={t('search')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Select
                    isClearable
                    options={groupFilterOptions}
                    value={selectedGroupFilter}
                    onChange={setSelectedGroupFilter}
                    placeholder={t('filterByGroup')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th onClick={() => handleSort('email')} style={{ cursor: 'pointer' }}>
                    {t('email')} {sortConfig.key === 'email' && (
                      <span>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th onClick={() => handleSort('display_name')} style={{ cursor: 'pointer' }}>
                    {t('displayName')} {sortConfig.key === 'display_name' && (
                      <span>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th onClick={() => handleSort('expiry_date')} style={{ cursor: 'pointer' }}>
                    {t('expiryDate')} {sortConfig.key === 'expiry_date' && (
                      <span>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th>{t('groups')}</th>
                  <th>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user, index) => (
                  <tr key={index}>
                    <td>{user.email}</td>
                    <td>{user.display_name || '-'}</td>
                    <td>{formatDate(user.expiry_date)}</td>
                    <td>{userGroups[user.email]?.map(g => g.name).join(', ') || '-'}</td>
                    <td>
                      <Button variant="primary" size="sm" onClick={() => handleEditClick(user)}>
                        {t('edit')}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {totalUserPages > 0 && (
              <Pagination>
                <Pagination.First onClick={() => setCurrentUserPage(1)} disabled={currentUserPage === 1} />
                <Pagination.Prev
                  onClick={() => setCurrentUserPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentUserPage === 1}
                />
                {[...Array(Math.min(totalUserPages, 5))].map((_, i) => {
                  const pageNumber = currentUserPage + i - Math.min(currentUserPage - 1, 2);
                  return (
                    pageNumber > 0 && pageNumber <= totalUserPages && (
                      <Pagination.Item
                        key={pageNumber}
                        active={pageNumber === currentUserPage}
                        onClick={() => setCurrentUserPage(pageNumber)}
                      >
                        {pageNumber}
                      </Pagination.Item>
                    )
                  );
                })}
                <Pagination.Next
                  onClick={() => setCurrentUserPage((prev) => Math.min(prev + 1, totalUserPages))}
                  disabled={currentUserPage === totalUserPages}
                />
                <Pagination.Last onClick={() => setCurrentUserPage(totalUserPages)} disabled={currentUserPage === totalUserPages} />
              </Pagination>
            )}
          </Form>
        </Tab>

        <Tab eventKey="groupedReport" title={t('groupedUsageReport')}>
          <Form className="mb-3">
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>{t('startDate')}</Form.Label>
                  <DatePicker
                    className="form-control mx-1"
                    selected={startDate}
                    onChange={(date) => handleDateChange(date, 'start')}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>{t('endDate')}</Form.Label>
                  <DatePicker
                    className="form-control mx-1"
                    selected={endDate}
                    onChange={(date) => handleDateChange(date, 'end')}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>{t('filterBy')}</Form.Label>
                  <div className="d-flex flex-column gap-2">
                    <Select
                      isMulti
                      options={emailOptions}
                      value={selectedEmails}
                      onChange={(selected) => {
                        handleEmailFilterChange(selected);
                        // Clear group filter when emails are selected
                        if (selected.length > 0) {
                          setSelectedGroupsFilter([]);
                        }
                      }}
                      placeholder={t('selectEmails')}
                      isDisabled={selectedGroupsFilter.length > 0}
                    />
                    <Select
                      isMulti
                      options={groupFilterOptions}
                      value={selectedGroupsFilter}
                      onChange={(selected) => {
                        setSelectedGroupsFilter(selected);
                        // Clear email filter when groups are selected
                        if (selected.length > 0) {
                          setSelectedEmails([]);
                        }
                      }}
                      placeholder={t('selectGroups')}
                      isDisabled={selectedEmails.length > 0}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          {renderGroupedUsageReport()}
        </Tab>

        <Tab eventKey="detailedReport" title={t('detailedUsageReport')}>
          {renderDetailedUsageReport()}
        </Tab>

        <Tab eventKey="groups" title={t('groups')}>
          <div className="mb-3">
            <Button variant="primary" onClick={() => setShowGroupModal(true)}>
              {t('createNewGroup')}
            </Button>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t('name')}</th>
                <th>{t('description')}</th>
                <th>{t('createdAt')}</th>
                <th>{t('updatedAt')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group) => (
                <tr key={group.id}>
                  <td>{group.name || '-'}</td>
                  <td>{group.description || '-'}</td>
                  <td>{formatDate(group.created_at) || '-'}</td>
                  <td>{formatDate(group.updated_at) || '-'}</td>
                  <td>
                    <Button 
                      size="sm" 
                      variant="primary" 
                      onClick={() => handleEditGroupClick({
                        id: group.id,
                        name: group.name || '',
                        description: group.description || ''
                      })}
                      className="me-2"
                    >
                      {t('edit')}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('editSubscription')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>{t('email')}</Form.Label>
              <Form.Control type="email" value={selectedUser?.email} readOnly />
            </Form.Group>
            <Form.Group controlId="formDisplayName">
              <Form.Label>{t('displayName')}</Form.Label>
              <Form.Control type="text" value={selectedUser?.display_name || ''} readOnly />
            </Form.Group>
            <Form.Group controlId="formExpiryDate">
              <Form.Label>{t('expiryDate')}</Form.Label>
              <Form.Control
                type="date"
                value={expiryDate?.split('T')[0] || ''}
                onChange={(e) => setExpiryDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formAdmin">
              <Form.Check
                type="checkbox"
                label={t('makeAdmin')}
                checked={isUserAdmin}
                onChange={(e) => setIsUserAdmin(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            {t('close')}
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {t('saveChanges')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Group Creation Modal */}
      <Modal show={showGroupModal} onHide={() => setShowGroupModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('createNewGroup')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGroupName">
              <Form.Label>{t('groupName')}</Form.Label>
              <Form.Control
                type="text"
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formGroupDescription">
              <Form.Label>{t('groupDescription')}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={newGroupDescription}
                onChange={(e) => setNewGroupDescription(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowGroupModal(false)}>
            {t('cancel')}
          </Button>
          <Button variant="primary" onClick={handleCreateGroup}>
            {t('save')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Group Modal */}
      <Modal show={showEditGroupModal} onHide={() => setShowEditGroupModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('editGroup')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>{t('groupName')}</Form.Label>
              <Form.Control
                type="text"
                value={selectedGroup?.name || ''}
                onChange={(e) => setSelectedGroup(prev => prev ? {...prev, name: e.target.value} : null)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupDescription">
              <Form.Label>{t('groupDescription')}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={selectedGroup?.description || ''}
                onChange={(e) => setSelectedGroup(prev => prev ? {...prev, description: e.target.value} : null)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupUsers">
              <Form.Label>{t('users')}</Form.Label>
              <Select
                isMulti
                value={groupUsers}
                options={availableUsers}
                onChange={handleUpdateGroupUsers}
                isSearchable={true}
                placeholder={t('selectUsers')}
                noOptionsMessage={() => t('noUsersAvailable')}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditGroupModal(false)}>
            {t('cancel')}
          </Button>
          <Button variant="primary" onClick={handleUpdateGroup}>
            {t('save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AdminPanel;