import React, { useEffect, useState } from 'react';
import { Container, ListGroup, Spinner, Button, Alert, Form, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuth } from './AuthContext';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';
import { Link } from 'react-router-dom';
import { Buffer } from 'buffer';
import { useSavedDrugs } from './SavedDrugsContext';

const Profile = () => {
  const { t, i18n } = useTranslation();
  const { currentUser } = useAuth();
  const [savedDrugs, setSavedDrugs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setSavedDrugsCount } = useSavedDrugs();
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    displayName: currentUser?.displayName || '',
    email: currentUser?.email || '',
    subscription: '',
    expiryDate: '',
    image: ''
  });

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const getProfile = httpsCallable(functions, 'getprofile');
        const response = await getProfile({ email: currentUser.email });
        setProfileData({
          displayName: (response.data.display_name || currentUser?.displayName) || '',
          email: currentUser?.email || '',
          subscription: response.data.subscription_type || '',
          expiryDate: response.data.expiry_date || '',
          image: response.data.image || ''
        });
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    if (currentUser) {
      fetchProfileData();
      fetchData();
    }
  }, [currentUser]);

  const fetchData = async () => {
    try {
      // Fetch saved drugs
      const getSavedDrugs = httpsCallable(functions, 'getsaveddrugs');
      const drugsResponse = await getSavedDrugs({ email: currentUser.email, locale: i18n.language });
      setSavedDrugs(drugsResponse.data.results);
      setSavedDrugsCount(drugsResponse.data.results.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getPdidHex = (pdid) => {
    if (typeof pdid === 'object' && pdid !== null) {
      const pdidArray = Object.values(pdid);
      return Buffer.from(pdidArray).toString('hex');
    }
    return '';
  };

  const handleDeleteDrug = async (pdid) => {
    try {
      const callableFunction = httpsCallable(functions, 'deleteSavedDrug');
      await callableFunction({ email: currentUser.email, pdid: getPdidHex(pdid) });
      fetchSavedDrugs();
    } catch (error) {
      console.error('Error deleting saved drug:', error);
    }
  };

  const renderLimitError = () => {
    if (!error) return null;

    return (
      <Alert variant="warning" className="mb-4">
        <Alert.Heading>
          {error.type === 'daily_limit_reached' ? t('dailyLimitReached') : t('trialLimitReached')}
        </Alert.Heading>
        <p>
          {error.type === 'daily_limit_reached'
            ? t('dailyLimitMessage', { limit: error.limit })
            : t('trialLimitMessage', { limit: error.limit })}
        </p>
        {error.type === 'trial_limit_reached' && (
          <div className="mt-2">
            <Button variant="primary" as={Link} to="/subscribe">
              {t('upgradeAccount')}
            </Button>
          </div>
        )}
      </Alert>
    );
  };

  const fetchSavedDrugs = async () => {
    try {
      const getSavedDrugs = httpsCallable(functions, 'getsaveddrugs');
      const drugsResponse = await getSavedDrugs({ email: currentUser.email, locale: i18n.language });
      setSavedDrugs(drugsResponse.data.results);
      setSavedDrugsCount(drugsResponse.data.results.length);
    } catch (error) {
      console.error('Error fetching saved drugs:', error);
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      const updateProfile = httpsCallable(functions, 'updateprofile');
      await updateProfile({
        email: currentUser.email,
        displayName: profileData.displayName
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">{t('loading')}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container className="mt-4 mb-4">
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2>{t('profile')}</h2>
            <Button variant="outline-primary" onClick={() => setIsEditing(!isEditing)}>
              {isEditing ? t('cancel') : t('edit')}
            </Button>
          </div>

          {isEditing ? (
            <Form onSubmit={handleUpdateProfile}>
              <Form.Group className="mb-3">
                <Form.Label>{t('displayName')}</Form.Label>
                <Form.Control
                  type="text"
                  value={profileData.displayName}
                  onChange={(e) => setProfileData({ ...profileData, displayName: e.target.value })}
                />
              </Form.Group>
              <Button type="submit" variant="primary">
                {t('save')}
              </Button>
            </Form>
          ) : (
            <>
              <p><strong>{t('displayName')}:</strong> {profileData.displayName}</p>
              <p><strong>{t('email')}:</strong> {profileData.email}</p>
              {profileData.image && (
                <img 
                  src={profileData.image} 
                  alt={t('profileImage')} 
                  className="rounded-circle mb-3" 
                  style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                />
              )}
            </>
          )}
        </Card.Body>
      </Card>

      {renderLimitError()}
      <h3>{t('savedDrugs')}</h3>
      {savedDrugs.length === 0 ? (
        <p>{t('noSavedDrugs')}</p>
      ) : (
        <ListGroup>
          {savedDrugs.map((drug) => (
            <ListGroup.Item key={getPdidHex(drug.pdid)} className="d-flex justify-content-between align-items-center">
              <div>
                <strong>{drug.brand_name}</strong> - {drug.generic_name}
              </div>
              <div>
                <Button as={Link} to={`/drugs/details/${getPdidHex(drug.pdid)}`} variant="primary" className="me-2">
                  {t('viewDetails')}
                </Button>
                <Button variant="danger" onClick={() => handleDeleteDrug(drug.pdid)}>
                  {t('delete')}
                </Button>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>
  );
};

export default Profile;