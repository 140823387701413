import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { auth, functions } from './firebase';
import { httpsCallable } from 'firebase/functions';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState(null);
  const [checkingSubscription, setCheckingSubscription] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        setIsAdmin(!!idTokenResult.claims.admin);
        
        setCheckingSubscription(true);
        try {
          const checkSubscription = httpsCallable(functions, 'checksubscription');
          const result = await checkSubscription();
          setIsSubscribed(result.data.isSubscribed);
          setIsTrial(result.data.isTrial);
          setSubscriptionExpiryDate(result.data.expiryDate);
          setHasAccess(result.data.hasAccess);
        } catch (error) {
          console.error('Error checking subscription:', error);
        } finally {
          setCheckingSubscription(false);
        }
      } else {
        setIsSubscribed(false);
        setIsTrial(false);
        setSubscriptionExpiryDate(null);
        setCheckingSubscription(false);
        setIsAdmin(false);
      }
      
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const hasValidAccess = useCallback(() => {
    if (!subscriptionExpiryDate) return false;
    const now = new Date();
    const expiryDate = new Date(subscriptionExpiryDate);
    
    // For trial users, check if within 30 days
    if (isTrial) {
      const trialDuration = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
      const creationDate = new Date(expiryDate.getTime() - trialDuration);
      return now >= creationDate && now <= expiryDate;
    }
    
    // For subscribed users, check if subscription is still valid
    return isSubscribed && now <= expiryDate;
  }, [isSubscribed, isTrial, subscriptionExpiryDate]);

  const value = {
    currentUser,
    isSubscribed,
    isTrial,
    hasAccess,
    subscriptionExpiryDate,
    isAdmin,
    loading,
    checkingSubscription,
    hasValidAccess
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
